import { Td, chakra } from '@chakra-ui/react';
import _ from 'lodash';
import { any, number, func, InferProps, instanceOf, shape } from 'prop-types';
import React, { FC, useRef } from 'react';

import { FwTooltip, useFwTheme, FwIcon } from 'components/base';
import {
  dateFormats,
  dateMax,
  dateMin,
  initDateTime,
  jsDateToString,
} from 'core/utils/date';

import { DateViewType } from '../../FwMask.structures';
import {
  dateIsBetween,
  getDateDiff,
  getTimeIndicator,
  sortLabels,
} from './../FwMask.Timeline.helpers';
import EventLabel, { Props as EventLabelProps } from './EventLabel';

const TimelineProgress: FC<Props> = ({
  data,
  endView,
  index,
  now,
  overlapDegree,
  processes,
  slotMultiplier,
  slots,
  selectedDate,
  selectedView,
  startView,
  handleOpen,
  handleProcessActionClick,
}) => {
  const {
    boxShadow,
    color,
    _disabled: { boxShadow: halfBoxShadow },
  } = useFwTheme();

  const betweenDateRef = useRef(
    dateIsBetween(new Date(), selectedDate.startDate, selectedDate.endDate)
  );

  const currentDayRange = (date: Date) => {
    const startClone = initDateTime(date, startView, 0, 0, 0);
    const endClone = initDateTime(date, endView, 0, 0, -1);

    const dateTime = date.getTime();

    return dateTime < startClone.getTime()
      ? startClone
      : dateTime > endClone.getTime()
      ? endClone
      : date;
  };

  const getLabelProps = (event) => {
    const { start: startEvent, end: endEvent, color, index } = event;

    // show event progress within this timerange's view limit: (startView, endView + 'timelineLastTime' mins)
    // pass all the time values to override all the time's parts
    const currentDateStartView = initDateTime(
      selectedDate.startDate,
      startView,
      0,
      0,
      0
    );
    const currentDateEndView = initDateTime(
      selectedDate.endDate,
      endView,
      0,
      0,
      -1
    );

    const max = dateMax(currentDayRange(startEvent), currentDateStartView);
    const min = dateMin(currentDayRange(endEvent), currentDateEndView);

    const startH = max.getHours() - startView;
    const endH = min.getHours() - startView;

    let slot = 0 /* start cell */,
      slots = 0 /* length (number of cells) */,
      left = 0; /* offset in start cell */

    if (
      selectedView === DateViewType.day ||
      selectedView === DateViewType.week
    ) {
      const startMinutes = max.getMinutes();
      const startDecimalSlot = startMinutes / 30.0;
      const startM = Math.floor(startMinutes / 30.0);

      const endMinutes = min.getMinutes();
      const endDecimalSlot = endMinutes / 30.0;

      const viewDiff = endView - startView;

      slot =
        2 *
          (startH + viewDiff * getDateDiff(max, currentDateStartView)) *
          slotMultiplier +
        startM;
      slots =
        (2 * (endH - startH) +
          2 * viewDiff * getDateDiff(max, min) +
          endDecimalSlot -
          startDecimalSlot) *
        slotMultiplier;
      left = startDecimalSlot * slotMultiplier - startM;

      //const { start, length, end } = event;

      //console.log(slot, slots, startH, endH, startDecimalSlot, endDecimalSlot);
      //if (progressRef.current) {
      //  const totalWidth = progressRef.current.offsetWidth;

      //  const timeStartVal = dateToSecond(start);
      //  const timeEndVal = dateToSecond(end);
      //  const timeLengthVal = dateToSecond(length);

      //  const widthTime = timelineEnd - timelineStart;

      //  if (totalWidth) {
      //    //offset = timeStartVal - timelineStart;
      //    //slot = offset / 3600;
      //    //left = (offset / widthTime) * 100;
      //    width =
      //      ((timeLengthVal || timeEndVal - timeStartVal) / widthTime) * 100;
      //    compact = (width / 100) * totalWidth < 35 ? true : false;
      //  }
      //}
    } else {
      const startSlot = Math.floor(startH / 12);
      const leftSlot = startH % 12;
      const startMinutes = max.getMinutes();
      const startDecimalSlot = startMinutes / 60;

      const endMinutes = min.getMinutes();
      const endDecimalSlot = endMinutes / 60;

      slot = 2 * getDateDiff(max, currentDateStartView) + startSlot;
      slots =
        (endH + endDecimalSlot - leftSlot - startDecimalSlot) / 12.0 +
        2 * getDateDiff(max, min) -
        startSlot;
      left = (leftSlot + startDecimalSlot) / 12.0;
    }

    const /*offset = 0,*/
      width = 0,
      compact = false,
      backgroundColor = color;

    return {
      slot,
      slots,
      left,
      index,
      width,
      compact,
      backgroundColor,
    };
  };

  const labels = sortLabels(data.labels);

  const otherSlots = [];

  // time indicator
  const timeToPosition = (timeIndicator: number) => {
    let position: number;

    if (timeIndicator >= startView && timeIndicator <= endView) {
      const intervalle = 200;

      if (
        selectedView === DateViewType.day ||
        selectedView === DateViewType.week
      ) {
        position = (timeIndicator - startView) * intervalle * slotMultiplier;
      } else {
        position = (timeIndicator * intervalle) / 24;
      }
    }

    return position ? `${position}%` : undefined;
  };

  const labelRenders: [number, EventLabelProps, Date][] = _.map(
    labels,
    (lbl) => {
      const { key, start, end, text } = lbl;
      const lblProps = getLabelProps(lbl);

      const startTime = jsDateToString(start, dateFormats.time);
      const endTime = jsDateToString(end, dateFormats.time);

      return [
        lblProps.slot,
        {
          ...lblProps,
          content: text,
          endTime: endTime,
          eventKey: key,
          overlapDegree,
          processes: processes,
          startTime: startTime,
          handleOpen: handleOpen,
          handleProcessActionClick: handleProcessActionClick,
          onDoubleClick: (e) => {
            e.stopPropagation();
            handleOpen(key);
          },
        },
        start,
      ];
    }
  );

  const position = timeToPosition(getTimeIndicator(now));
  const kPosition =
    2 *
    getDateDiff(new Date(), selectedDate.startDate) *
    (selectedView === DateViewType.month
      ? 1
      : (endView - startView) * slotMultiplier);

  for (let k = 0; k < slots; k++) {
    const labelsInSlot = _.filter(
      labelRenders,
      (lr) => lr[0] >= k && lr[0] < k + 1
    );
    const labelsProps = _.map(labelsInSlot, (lc) => lc[1]);
    const halfHour = k % 2 === 0;

    // show caret only for first row
    const showCaret = index === 0;

    otherSlots.push(
      <Td
        key={k}
        position={k === kPosition ? 'relative' : undefined}
        boxShadow={halfHour ? boxShadow : halfBoxShadow}
      >
        {/* before labels to display in background */}
        {k === kPosition && betweenDateRef.current && position && (
          <chakra.div
            backgroundColor={color}
            pos="absolute"
            width="2px"
            top={0}
            bottom={0}
            left={position}
          >
            {showCaret && (
              <FwTooltip
                placement="top"
                text={jsDateToString(now, dateFormats.time)}
              >
                <chakra.div
                  position="relative"
                  top="-14px"
                  left="-12px"
                  fontSize="26px"
                  width="26px"
                >
                  <FwIcon color={color} name="RiArrowDropDownFill" />
                </chakra.div>
              </FwTooltip>
            )}
          </chakra.div>
        )}
        {/* labels */}
        {!_.isEmpty(labelsProps) && (
          <chakra.div pos="relative" top={0} m={0} h="100%" w="100%">
            {_.map(labelsProps, (props, idx) => (
              <EventLabel key={idx} {...props} />
            ))}
          </chakra.div>
        )}
      </Td>
    );
  }

  return <>{otherSlots}</>;
};

const propTypes = {
  data: any,
  endView: any,
  index: number,
  now: instanceOf(Date),
  overlapDegree: number,
  processes: any,
  selectedDate: shape({
    endDate: instanceOf(Date),
    startDate: instanceOf(Date),
  }),
  selectedView: any,
  slotMultiplier: number,
  slots: number,
  startView: any,
  // functions
  handleOpen: func,
  handleProcessActionClick: func,
  setExpandedRow: func,
};

export type Props = InferProps<typeof propTypes>;

TimelineProgress.propTypes = propTypes;

export default React.memo(TimelineProgress);
