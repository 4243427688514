import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  chakra,
} from '@chakra-ui/react';
import _ from 'lodash';
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FwMessage } from 'components/base';
import { FwModalProps, fwModalPT } from 'core/model';
import { useDrawerModal } from 'core/utils/useDrawerModal';

import {
  ModalMessage,
  ModalButton,
  getModalMessageProps,
  isRenderModalMessage,
} from './components';

const FwModal: FC<FwModalProps> = (props) => {
  const {
    additionalData,
    blur = true,
    cancelName,
    name,
    noDimmerClick,
    open,
    submitName,
    type,
    warning,
    onCancel,
    onChangeData,
    onSubmit,
  } = props;

  const { closeOnDimmerClick } = additionalData || {};

  const { t } = useTranslation();
  const initialRef = useRef();
  const { modalButtonProps, modalMesageExtendProps, systemClose } =
    useDrawerModal({ open, onCancel, onChangeData, onSubmit });

  const AnchorFocus = () => (
    <chakra.input
      _focusVisible={{ outline: '0 !important' }}
      ref={initialRef}
      height={0}
    />
  );

  return (
    <Modal
      isCentered
      autoFocus={false}
      isOpen={open}
      initialFocusRef={initialRef}
      returnFocusOnClose={false}
      closeOnOverlayClick={
        !_.isNil(closeOnDimmerClick) ? closeOnDimmerClick : !noDimmerClick
      }
      onClose={systemClose}
    >
      <ModalOverlay backdropFilter={blur ? 'blur(5px)' : undefined} />
      <ModalContent>
        <AnchorFocus />
        <FwMessage subtle message={name} type={type} />
        {isRenderModalMessage(props) && (
          <ModalBody>
            <ModalMessage
              {...getModalMessageProps(props)}
              {...modalMesageExtendProps}
            />
          </ModalBody>
        )}
        {warning && (
          <FwMessage
            small
            type="error"
            message={t(warning, { ns: ['glossary', 'common'] })}
          />
        )}
        <ModalFooter>
          <ModalButton
            {...modalButtonProps}
            cancelName={cancelName}
            submitName={submitName}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

FwModal.propTypes = fwModalPT;

export default FwModal;
